import React from 'react'
import Header from './Header'
import Footer from './Footer'

function Game1() {
  return (
<>
<Header/>
<iframe style={{width:"100%",height:"100vh"}}
        src="https://dnk-resource.wimobile.casinarena.com/resource-service/game.html?game=amazonqueen_prt&amp;partnercode=mockpartner&amp;realmoney=false&amp;demo=true&amp;gaffing=true"></iframe>
<Footer/>
</>
  )
}

export default Game1