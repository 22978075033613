import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <footer class="footer">
    <div class="container">
        <div class="footer-wrapper">
            <div class="footer-logo">Socail Canada</div>
            <div class="footer-disc">
                <span>Legal Notice:</span> This platform is intended for users aged 18 and over, with a focus on
                promoting
                responsible gaming. At Socail Canada, we are committed to ensuring player safety and
                satisfaction by offering various tools and resources to support informed decisions and encourage
                responsible gameplay.
            </div>
            <div class="footer-pages">
                <div class="footer-content">
                    <h3>Menu</h3>
                    <nav>
                        <ul class="footer-menu">
                            <li><Link to="./">Home</Link></li>
                            <li><Link to="/about/">About</Link></li>
                            <li><Link to="/games/">Play</Link></li>
                            <li><Link to="/contact/">Contact</Link></li>
                            <li><Link to="/privacy/">Privacy Policy</Link></li>
                            <li><Link to="/terms/">Terms &amp; Conditions</Link></li>
                            <li><Link to="/cookies/">Cookies Policy</Link></li>
                        </ul>
                    </nav>
                </div>


            </div>
        </div>

    </div>
</footer>
  )
}

export default Footer