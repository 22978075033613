import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';

import 'bootstrap/dist/css/bootstrap.css'
import "./App.css"
import About from './components/About';
import Game from './components/Game';
import Contact from './components/Contact';
import Terms from './components/Terms';
import Cookie from './components/Cookie';
import Privacy from './components/Privacy';
import Game1 from './components/Game1';
import Game2 from './components/Game2';
import Game3 from './components/Game3';





const App=()=> {
  return (
    <Router>
      <div className="App">
       
       
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/games" element={<Game />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/cookies" element={<Cookie />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/game1" element={<Game1 />} />
          <Route path="/game2" element={<Game2 />} />
          <Route path="/game3" element={<Game3 />} />
          
        </Routes>
      
      </div>
      
    </Router>
  );
}

export default App;