import React from 'react'
import Header from './Header'
import Footer from './Footer'

function Game1() {
  return (
<>
<Header/>
<iframe style={{width:"100%",height:"100vh"}}
        src="https://gameaccount-live.spingames.net/slot/everi_matahari?playerid=guest"></iframe>
<Footer/>
</>
  )
}

export default Game1