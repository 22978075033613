import React from 'react'
import Header from './Header'
import Footer from './Footer'

function Game() {
  return (
<>
<Header/>
<section class="game">
    <div class="container">
        <div class="game-wrapper">
            <a href="/game1/" class="game-container game1">
                <img src="/mata-hari.jpg" alt=""/>
                <span class="game-txt">Mata Hari</span>
            </a>

            <a href="/game2/" class="game-container game2">
                <img src="/5xmagic-.jpg" alt="/"/>
                <span class="game-txt">5x Magic</span>
            </a>

            <a href="/game3/" class="game-container game3">
                <img src="/game3.jpeg" alt=""/>
                <span class="game-txt">Amazon Queen Slot</span>
            </a>
        </div>
    </div>
</section>
<Footer/>
</>
  )
}

export default Game