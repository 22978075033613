import React from 'react'
import Header from './Header'
import Footer from './Footer'

function Game1() {
  return (
<>
<Header/>
<iframe style={{width:"100%",height:"100vh"}}
        src="https://asccw.playngonetwork.com/casino/ContainerLauncher?pid=2&amp;gid=5xmagic&amp;lang=en_GB&amp;practice=1&amp;channel=desktop&amp;demo=2"></iframe>
        
<Footer/>
</>
  )
}

export default Game1